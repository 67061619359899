<template>
  <div class="elite-tabs-wrapper-content">
    <div class="custom-header">
      <div class="row">
          <div class="col-12 text-right">
              <!--<base-button
                  @click="printAwardCertificates()"
                  type="button"
                  class="elite-button btn-success text-right"
                  v-if="property.is_sold && property.sold_amount"
              >
                <span class="btn-inner--icon mr-1">
                  <i class="fa fa-file"></i>
                </span>
                <span class="btn-inner--text">
                  {{ $t("PROPERTIES.PRINT_AWARD_CERTIFICATE") }}
                </span>
              </base-button>-->
            </div>
        </div>
    </div>
    <h3 class="mb-4">
      {{ property.address }},
      <span class="text-uppercase">
        {{ property.city }} {{ property.state }} </span
      >,
      {{ property.zipcode }}
    </h3>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TAXABLE") }}</dt>
          <dd class="col-sm-8" v-if="property.taxable !== undefined">
            <span v-if="property.taxable">
              {{ $t("COMMON.YES") }}
            </span>
            <span v-else>
              {{ $t("COMMON.NO") }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.ACQUISITION_NUMBER") }}</dt>
          <dd class="col-sm-8">
            {{ property.acquisition_number }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.ACQUISITION_METHODE") }}</dt>
          <dd class="col-sm-8">
            {{ property.acquisition_method }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.PROPERTY_NUMBER") }}</dt>
          <dd class="col-sm-8">
            {{ property.property_number }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.DISPLAY_ON_WEB_SITE") }}</dt>
          <dd class="col-sm-8">
            <badge :type="'success'" v-if="property.active">
              {{ $t(`COMMON.YES`) }}
            </badge>
            <badge :type="'danger'" v-else>
              {{ $t(`COMMON.NO`) }}
            </badge>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8" v-if="property.status">
            <property-status-badge-component :property="property" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.CANCEL_REASON") }}</dt>
          <dd class="col-sm-8" v-if="property?.cancel_reason">
            {{ property?.cancel_reason }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.SOLD_AMOUNT") }}</dt>
          <dd class="col-sm-8" v-if="property.sold_amount">
            {{ $formatCurrency(property.sold_amount) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.BID_STARTING_AMOUNT") }}</dt>
          <dd class="col-sm-8" v-if="property.bid_starting_amount">
            {{ $formatCurrency(property.bid_starting_amount) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.SOLD_AT") }}</dt>
          <dd v-if="property.sold_at" class="col-sm-8">
            {{ $formatDate(property.sold_at, "dddd D MMMM YYYY") }}
          </dd>
          <dd v-else class="col-sm-8">
            <span v-if="property?.auction?.start_at">
              {{ $formatDate(property?.auction?.start_at, "LLL") }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.SALE_CONFIRMED_AT") }}</dt>
          <dd class="col-sm-8" v-if="property.sale_confirmed_at">
            {{
              $formatDate(property.sale_confirmed_at, "dddd D MMMM YYYY HH:mm")
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("PROPERTIES.PROPERTIES_TAXES_DUE_SCHOOL") }}
          </dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(property.owed_taxes_school_board) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("PROPERTIES.PROPERTIES_TAXES_DUE_MUNICIPAL") }}
          </dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(property.owed_taxes_municipality) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.PROPERTY_TAX_DUE") }}</dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(property.total_taxes) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.APPLICABLES_FEES") }}</dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(property.total_fees) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.MRC_FEES") }}</dt>
          <dd class="col-sm-8" v-if="property.mrc_fees">
            {{ $formatCurrency(property.mrc_fees) }}
          </dd>
        </dl>

        <dl class="row d-none">
          <dt class="col-sm-4">{{ $t("COMMON.SUBTOTAL") }}</dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(property.sub_total) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.PROPERTY_TOTAL_DUE") }}</dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(property.total) }}
          </dd>
        </dl>

        <template v-if="property?.other_fees?.length">
          <dl class="row mt-5">
            <dt class="col-sm-8 text-center">{{ $t("COMMON.OTHER_FEES") }}</dt>
          </dl>

          <dl class="row" v-for="(fee, key) in property?.other_fees" :key="fee.id">
            <dt class="col-sm-4">{{ fee.name }}</dt>
            <dd class="col-sm-8">
              {{ $formatCurrency(fee.calculated_amount) }}
            </dd>
          </dl>
        </template>

        <dl class="row mt-5">
          <dt class="col-sm-4">{{ $t("PROPERTIES.REGISTRATION_NUMBER") }}</dt>
          <dd class="col-sm-8">
            {{ property.registration_number }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("PROPERTIES.INFOS_LINKS") }}
          </dt>
          <dd class="col-sm-8">
            <a :href="line.link" class="info-link-item" target="_blank" v-for="(line, index) in property.infos_links" :key="index">
              <i class="fa fa-link"></i> {{ line.title }}
            </a>
            
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.BATCH_NUMBER") }}</dt>
          <dd class="col-sm-8">
            <span v-for="(item, idx) in property.batch_numbers" :key="idx">
              <template>
                {{ `${$numberFormat(item.value)}` }}
                {{ property.batch_numbers.length - 1 > idx ? ", " : " " }}
              </template>
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.TYPE") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{
                property.property_type && property.property_type != "" ? $t(`PROPERTIES.PROPERTIES_TYPE_LONG_${property.property_type}`) : "-"
              }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.REGISTRATION_DIVISION") }}</dt>
          <dd class="col-sm-8 text-uppercase">
            <div>{{ property.registrationDivision?.name }}</div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.REFERENCE_CSS") }}</dt>
          <dd class="col-sm-8">
            <div>{{ property.reference_css }}</div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.ORIGIN") }}</dt>
          <dd class="col-sm-8">
            <div>{{ property.origin && property.origin != "" ? $t(`PROPERTIES.PROPERTIES_ORIGIN_${property.origin}`) : "-" }}</div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8 text-uppercase">
            <div v-html="property.address"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
          <dd class="col-sm-8 text-uppercase">
            <div v-html="property.city"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd class="col-sm-8">
            <div v-html="property.zipcode"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
          <dd class="col-sm-8 text-uppercase">
            <div v-html="property.state"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
          <dd class="col-sm-8 text-uppercase">
            <div v-html="property.country"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.DESIGNATION") }}</dt>
          <dd class="col-sm-8">
            {{ property.designation_translate.fr ? property.designation_translate.fr : property.designation }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NOTE") }}</dt>
          <dd class="col-sm-8">
            <div v-html="property.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.AUCTION") }}</dt>
          <dd class="col-sm-8">
            <router-link :to="$objectViewRoute(property.auction)">
                {{ property.auction?.name }}
            </router-link>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_BY") }}</dt>
          <dd class="col-sm-8">
            <users :users="[property.createdBy]" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_BY") }}</dt>
          <dd class="col-sm-8">
            <users :users="[property.updatedBy]" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="property.allowedLocations" />
          </dd>
        </dl>

        <dl class="row" v-if="0">
          <dt class="col-sm-4">{{ $t("COMMON.OWNERS") }}</dt>
          <dd class="col-sm-8">
            <contacts :contacts="property.owners" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="property.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(property.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(property.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { TAX_TYPE_AMOUNT, TAX_TYPE_PERCENTAGE } from "@/constants/taxes";
import Users from "@/components/Users.vue";
import Contacts from "@/components/Contacts.vue";
import PropertyStatusBadgeComponent from "../components/PropertyStatusBadgeComponent";
import swal from "sweetalert2";
import moment from "moment";

export default {
  name: "property-view-global",

  props: ["property"],

  components: {
    Users,
    Contacts,
    PropertyStatusBadgeComponent,
  },

  data() {
    return { 
      TAX_TYPE_PERCENTAGE,
      TAX_TYPE_AMOUNT,
    };
  },

  computed: {},

  created() {},

  methods: {
    propertyUpdated() {
      this.$emit("propertyUpdated", true);
    },
    async printAwardCertificates() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      let params = {id: this.property.id};
      try {
        const pdfResponse = await this.$store.dispatch(
          "properties/printAwardCertificates", params
        );

        const pdfPath = window.URL.createObjectURL(
          new Blob([pdfResponse.data], { type: "application/pdf" })
        );

        const downloadPdfLink = document.createElement("a");
        downloadPdfLink.href = pdfPath;
        downloadPdfLink.download =
          this.$t("PROPERTIES.PRINT_AWARD_CERTIFICATE_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".pdf";

        document.body.appendChild(downloadPdfLink);
        downloadPdfLink.click();
        document.body.removeChild(downloadPdfLink);

        swal.close();
      } catch (error) {
        console.log(error);
        swal.close();
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      }
    },
  },

  mounted() {},

  watch: {
    property(property) {},
  },
};
</script>
<style lang="scss" scoped>
  .info-link-item{
    background-color: #dceeec;
    padding: 5px;
    margin: 5px;
    border: 1px solid #5ca4c8;
    border-radius: 11px;
  }
</style>
