<template>
  <div class="elite-tabs-wrapper-content">
  <div class="page-wrapper-table">
    <div class="custom-header mt-4">
      <h3>{{ $t("PROPERTIES.APPLICABLES_FEES") }}</h3>
      <div class="">
        <base-button
            @click="openPropertyFeeModal()"
            type="button"
            class="elite-button add"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus-circle"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("AUCTIONS.APPLY_FEES") }}
          </span>
        </base-button>
      </div>
    </div>
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="propertyFees"
        >
          <el-table-column :label="$t('PROPERTIES.TYPE_FEE')" min-width="30">
            <template v-slot="{ row }">
              {{ $t(`PROPERTIES.TYPE_${row.type_fee}`) }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('AUCTIONS.AMOUNT')" min-width="30">
            <template v-slot="{ row }">
              <span v-if="row.amount">{{
                $formatCurrency(row.amount)
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('PROPERTIES.DATE_FEE')" min-width="30">
            <template v-slot="{ row }">
              <span v-if="row.amount">{{ row.date ? $formatDate(row.date) : '-' }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column min-width="10">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip :content="$t('COMMON.EDIT')" placement="top">
                <a
                  type="text"
                  @click="openPropertyFeeModal(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
  <property-fee-form
      v-if="propertyFeeData"
      :property="property"
      :auction="property.auction.id"
      :propertyFee="propertyFeeData"
      :showModal="showPropertyFeeModal"
      @onSubmitPropertyFeeForm="handlePropertyFeeFormSubmit"
      @onClosePropertyFeeModal="closePropertyFeeModal"
    />
</div>
  
</template>

<script>
import swal from "sweetalert2";
import { Table, TableColumn, Tooltip, Button } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { ORDER_STATUS_DRAFT } from "@/constants/orders";
import PropertyFeeForm from "./PropertyFeeForm.vue";
import defaultPropertyFee from "../../PropertyManagement/defaultPropertyFee";
import {cloneDeep} from "lodash";
import {
  PROPERTY_STATUS,
  PROPERTY_TAXES,
  PROPERTY_TYPES,
  PROPERTY_ORIGINS,
  STATUS_ACTIVE,
  STATUS_APPROVED,
  STATUS_CANCEL,
  STATUS_CONFIRMED,
  STATUS_PENDING,
  STATUS_SOLD,
} from "@/constants/properties";

export default {
  name: "property-applicables-fees-list-table",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    PropertyFeeForm,
  },

  mixins: [formMixin],

  props: ["property", "propertyFee", "showModal", "formErrors"],

  data() {
    return {
      propertyFees: [],
      showPropertyFeeModal: false,
      propertyFeeData: null,
      loading: false,
      propertyFeeModel: cloneDeep(defaultPropertyFee),
    };
  },

  computed: {},

  methods: {
    async get(propertyFee) {
      try {
        this.loading = true;
        await this.$store.dispatch("propertyFees/get", propertyFee.id);
        this.propertyFeeData = this.$store.getters["propertyFees/propertyFee"];
        
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    openPropertyFeeModal(propertyFee = null) {
      this.showPropertyFeeModal = true;
      if(propertyFee){
        this.get(propertyFee);
      }else{
        this.propertyFeeData = this.propertyFeeModel;
        this.propertyFeeData.type_fee = 'ON_PROPERTY';
        const property = {
          id : this.property.id,
          type : "properties"
        };
        this.propertyFeeData.property = property;
      }
      
    },

    closePropertyFeeModal() {
      this.showPropertyFeeModal = false;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
        };

        if (this.property) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              property_id: this.property.id,
            },
          };
        }
        await this.$store.dispatch("propertyFees/list", params);
        this.propertyFees = this.$store.getters["propertyFees/list"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async handlePropertyFeeFormSubmit(propertyFeeData) {
      if(this.property.status != STATUS_CONFIRMED){
        const confirmation = await swal.fire({
          title: this.$t("PROPERTIES.THIS_PROPERTY_IS_NOT_CONFIRMED"),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (confirmation.value === true) {
          this.loading = true;
          try {
            if(propertyFeeData.id){
              await this.$store.dispatch("propertyFees/update", propertyFeeData);
            }else{
              await this.$store.dispatch("propertyFees/add", propertyFeeData);
            }
            this.$emit("onPropertyFeesUpdated", propertyFeeData);

            this.$notify({
              type: "success",
              message: propertyFeeData.id ? this.$t("COMMON.ITEM_EDITED") : this.$t("COMMON.ITEM_ADDED"),
            });

            this.loading = false;
            this.showPropertyFeeModal = false;
          } catch (error) {
            console.log(error);
            this.itemsFormError = error.response.data.errors;
            this.$notify({
              type: "danger",
              message: this.itemsFormError[0].detail,
            });
            this.loading = false;
          }
        }else{
          this.loading = false;
          this.showPropertyFeeModal = false;
        }

      }else{
        this.loading = true;
          try {
            if(propertyFeeData.id){
              await this.$store.dispatch("propertyFees/update", propertyFeeData);
            }else{
              await this.$store.dispatch("propertyFees/add", propertyFeeData);
            }
            this.$emit("onPropertyFeesUpdated", propertyFeeData);

            this.$notify({
              type: "success",
              message: propertyFeeData.id ? this.$t("COMMON.ITEM_EDITED") : this.$t("COMMON.ITEM_ADDED"),
            });

            this.loading = false;
            this.showPropertyFeeModal = false;
          } catch (error) {
            console.log(error);
            this.itemsFormError = error.response.data.errors;
            this.$notify({
              type: "danger",
              message: this.itemsFormError[0].detail,
            });
            this.loading = false;
          }
      }
    },
  },

  mounted() {
    this.$parent.$on("propertyFeesUpdated", this.getListDebounced);
  },

  beforeDestroy() {
    this.$parent.$off("propertyFeesUpdated", this.getListDebounced);
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
  },
};
</script>

<!-- <style>
.purchasesOrder-item-excerpt p,
.purchasesOrder-item-excerpt,
.purchasesOrder-item-excerpt * {
  font-size: 0.7rem;
}
</style> -->
