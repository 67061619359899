<template>
  <div class="phone-group">
    <!--<label class="form-control-label"> {{ label }} </label>-->
    <div
      v-for="(line, key) in infosLinksModel"
      :key="key"
      class="phone-group-wrapper"
    >
      <div class="phone-group-wrapper-item">
        <base-input
          :label="$t('PROPERTIES.TITLE')"
          :placeholder="$t('PROPERTIES.TITLE')"
          v-model="line.title"
          @change="linksChanged"
        />
      </div>
      <div class="phone-group-wrapper-item">
        <base-input
          :label="$t('PROPERTIES.URL')"
          :placeholder="$t('PROPERTIES.URL')"
          v-model="line.link"
          @change="linksChanged"
        />
      </div>
      <div class="phone-group-wrapper-item remove">
        <label class="form-control-label mb-4"></label>
        <base-button
          type="button"
          class="form-control btn"
          @click.prevent="() => removeLink(key)"
        >
          <i class="fal fa-times"></i>
        </base-button>
      </div>
    </div>
    <base-button type="button" class="btn add" @click.prevent="addLink">
      <i class="far fa-plus"></i>
    </base-button>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { cloneDeep } from "lodash";
import BaseInput from "@/components/Inputs/BaseInput.vue";

export default {
  name: "infos-links-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseInput,
  },

  props: {
    infosLinks: {
      type: Array,
      default: null,
      description: "infosLinks array",
    },
    label: {
      type: String,
      default: null,
      description: "Label of field",
    },
  },

  data() {
    return {
      infosLinksModel: this.infosLinks,
    };
  },

  setup() {},

  created() {},

  methods: {
    addLink() {
      this.infosLinksModel.push({
        title: null,
        link: null,
      });
      this.linksChanged();
    },
    removeLink(key) {
      this.infosLinksModel = this.infosLinksModel.filter(
        (_, idx) => idx !== key
      );
      this.linksChanged();
    },
    linksChanged() {
      const linksData = this.infosLinksModel.map((item) => {
        return {
          title: item.title,
          link: item.link,
        };
      });
      this.$emit("infosLinksChanged", linksData);
    },
  },

  watch: {
    infosLinks(infosLinks) {
      this.infosLinksModel = cloneDeep(infosLinks);
    },
  },
};
</script>
