var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('modal',{attrs:{"show":_vm.showModal,"modal-classes":"modal-secondary","size":"lg"}},[_c('h3',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("PROPERTY_TRANSACTIONS.PAYMENT_OPERATION"))+" ")]),_c('form',{staticClass:"add-form pb-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('PROPERTIES_PAYMENTS.ATTENTION')} (*)`,"placeholder":_vm.$t('PROPERTIES_PAYMENTS.ATTENTION'),"type":"text"},model:{value:(_vm.propertiesPaymentsModel.attention),callback:function ($$v) {_vm.$set(_vm.propertiesPaymentsModel, "attention", $$v)},expression:"propertiesPaymentsModel.attention"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.customer}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('PROPERTIES_PAYMENTS.EMAIL')} (*)`,"placeholder":_vm.$t('PROPERTIES_PAYMENTS.EMAIL'),"type":"text"},model:{value:(_vm.propertiesPaymentsModel.email),callback:function ($$v) {_vm.$set(_vm.propertiesPaymentsModel, "email", $$v)},expression:"propertiesPaymentsModel.email"}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('property-payment-inputs',{on:{"transactionsChanged":(paymentsData) => {
              _vm.paymentsModel = paymentsData;
            }}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('PROPERTIES_PAYMENTS.DATE')} (*)`,"placeholder":_vm.$t('PROPERTIES_PAYMENTS.DATE'),"type":"number"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"placeholder":_vm.$t('PROPERTIES_PAYMENTS.DATE'),"config":{
              allowInput: true,
              enableTime: false,
              type: 'date',
              maxDate: 'today',
              locale: _vm.$flatPickrLocale(),
              disable: [
                function (date) {
                  return date.getDay() === 0;
                },
              ],
            }},model:{value:(_vm.propertiesPaymentsModel.date),callback:function ($$v) {_vm.$set(_vm.propertiesPaymentsModel, "date", $$v)},expression:"propertiesPaymentsModel.date"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.date}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":_vm.$t('PROPERTIES_PAYMENTS.EXCERPT'),"placeholder":_vm.$t('PROPERTIES_PAYMENTS.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.propertiesPaymentsModel.excerpt),callback:function ($$v) {_vm.$set(_vm.propertiesPaymentsModel, "excerpt", $$v)},expression:"propertiesPaymentsModel.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1)]),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.handleSubmit}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("COMMON.SAVE"))+" ")]),_c('button',{staticClass:"btn btn-link ml-auto",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CLOSE"))+" ")])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }