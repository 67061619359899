var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showModal)?_c('modal',{attrs:{"show":_vm.showModal,"modal-classes":"modal-secondary","size":"lg"}},[_c('form',{staticClass:"add-form pb-2"},[_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"type":"number","label":`${_vm.$t('AUCTIONS.AMOUNT')} (*)`,"placeholder":_vm.$t('AUCTIONS.AMOUNT')},model:{value:(_vm.propertyFee.amount),callback:function ($$v) {_vm.$set(_vm.propertyFee, "amount", $$v)},expression:"propertyFee.amount"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.amount}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('PROPERTIES.DATE_FEE')}`,"placeholder":_vm.$t('PROPERTIES.DATE_FEE')},on:{"change":_vm.onFormChanged},model:{value:(_vm.propertyFee.date),callback:function ($$v) {_vm.$set(_vm.propertyFee, "date", $$v)},expression:"propertyFee.date"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"placeholder":_vm.$t('PROPERTIES.DATE_FEE'),"config":{
            allowInput: true,
            enableTime: false,
            type: 'date',
            minDate: 'today',
            locale: _vm.$flatPickrLocale(),
            disable: [
              function (date) {
                return date.getDay() === 0;
              },
            ],
          }},on:{"on-change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.propertyFee.date),callback:function ($$v) {_vm.$set(_vm.propertyFee, "date", $$v)},expression:"propertyFee.date"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.date}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EXCERPT')}`,"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.propertyFee.description),callback:function ($$v) {_vm.$set(_vm.propertyFee, "description", $$v)},expression:"propertyFee.description"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.description}})],1)]),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.submitPropertyFeeForm}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("COMMON.SUBMIT"))+" ")]),_c('button',{staticClass:"btn btn-link ml-auto",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.closePropertyFeeModal}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CLOSE"))+" ")])])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }